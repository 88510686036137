import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Hamburger from '../../../images/hamburger.svg'
import Close from '../../../images/close.svg'
import './KapsalonHeader.scss'
import { Link } from 'gatsby'

const KapsalonHeader = ({ scrollToSalon, scrollToContact }) => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return <header className="c-kapsalon-header">
        <div className="c-kapsalon-header__left">
            <Link to="/">
                <StaticImage src={`../../../images/kapsalonkelly.jpg`} className="c-kapsalon-header__left-logo" placeholder="none" />
            </Link>
        </div>
        <div className="c-kapsalon-header__right">
            <ul className="c-kapsalon-header__menu">
                <li className="c-kapsalon-header__menu-item">Nieuws</li>
                <li className="c-kapsalon-header__menu-item" onClick={() => scrollToSalon()}>Het salon</li>
                <li className="c-kapsalon-header__menu-item" onClick={() => scrollToContact()}>Contact</li>
                <li className="c-kapsalon-header__menu-item c-kapsalon-header__menu-item--social"><StaticImage src={`../../../images/instagram.svg`} height={25} placeholder="none" className="c-kapsalon-header__menu-item--img" /></li>
                <li className="c-kapsalon-header__menu-item c-kapsalon-header__menu-item--social"><StaticImage src={`../../../images/facebook.svg`} height={25} placeholder="none" className="c-kapsalon-header__menu-item--img" /></li>
            </ul>
        </div>
        <div className="c-kapsalon-header__mobile" onClick={() => setMobileMenuOpen(prev => !prev)}>
            {mobileMenuOpen ? <Close height={40} width={40} className="c-kapsalon-header__hamburger" /> : <Hamburger height={40} width={40} className="c-kapsalon-header__hamburger" />}
            <ul className="c-kapsalon-header__mobile-menu" style={{display:mobileMenuOpen?"block":"none"}}>
                <li>
                    <a href="#" className="c-kapsalon-header__mobile-menu-item">Nieuws</a>
                </li>
            </ul>
        </div>
    </header>
}

export default KapsalonHeader;