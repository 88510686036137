import React from 'react'
import KapsalonTitle from '../shared/KapsalonTitle'
import './OnsContacteren.scss'
import MessageCircle from '../../../images/message-circle.svg'
import Info from '../../../images/info.svg'
import Schedule from '../../../images/schedule.svg'

import Mail from '../../../images/mail.svg'
import Phone from '../../../images/phone.svg'
import Facebook from '../../../images/facebook.svg'
import Instagram from '../../../images/instagram.svg'
import Pin from '../../../images/map-pin.svg'


export default function OnsContacteren({ reference }){

    return (<div className="c-ons-contacteren c-kapsalon-container" id="contact" ref={reference}>
            <KapsalonTitle Svg={MessageCircle} title="Ons contacteren"/>
            <div className="c-ons-contacteren__tile-wrapper">
                <div className="c-ons-contacteren__tile">
                    <h1><Info /> GEGEVENS</h1>
                    <ul className="c-ons-contacteren__coordinates-list">
                        <li className="c-ons-contacteren__coordinates-list-item"><Phone height={20}/> 0476772530</li>
                        <li className="c-ons-contacteren__coordinates-list-item"><Mail height={20} /> kapsalon.kelly@gmail.com</li>
                        <li className="c-ons-contacteren__coordinates-list-item"><Pin height={20} /> De Gavers 5, Harelbeke</li>
                        <li className="c-ons-contacteren__coordinates-list-item"><Facebook height={20} /> KapsalonKelly</li>
                        <li className="c-ons-contacteren__coordinates-list-item"><Instagram height={20} /> Kapsalon_Kelly</li>
                    </ul>
                    <h1><Schedule /> OPENINGSTIJDEN</h1>
                    <ol className="c-ons-contacteren__schedule-list">
                        <li>Maandag - Gesloten</li>
                        <li>Dinsdag - Gesloten</li>
                        <li>Woensdag - 10u - 19u</li>
                        <li>Donderdag - 10u - 19u</li>
                        <li>Vrijdag - 10u - 19u</li>
                        <li>Zaterdag - 10u - 19u</li>
                        <li>Zondag - 10u - 19u</li>
                    </ol>
                </div>
                <div className="c-ons-contacteren__tile">
                    <h1><Mail /> CONTACT FORMULIER</h1>
                    <form className="c-ons-contacteren__form">
                        <label>
                            <div className="c-ons-contacteren__form-title">Naam</div>
                            <input type="text" className="c-ons-contacteren__form-input"></input>
                        </label>
                        <label>
                            <div className="c-ons-contacteren__form-title">E-mail</div>
                            <input type="text" className="c-ons-contacteren__form-input"></input>
                        </label>
                        <label>
                            <div className="c-ons-contacteren__form-title">Onderwerp</div>
                            <input type="text" className="c-ons-contacteren__form-input"></input>
                        </label>
                        <label>
                            <div className="c-ons-contacteren__form-title">Bericht</div>
                            <textarea rows={10} className="c-ons-contacteren__form-input"></textarea>
                        </label>
                        <div onClick={() => alert('dit is een test website, contacteer ons voor meer info.')}>
                            <input className="c-ons-contacteren__form-btn" type="submit" value="Verzenden"></input>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}