import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Instagram from '../../../images/instagram.svg'
import KapsalonBtn from '../shared/KapsalonBtn'

import "./InstagramFeed.scss"

const InstagramFeed = () => {
    return (<div className="c-instagram-feed c-kapsalon-container">
        <h1 className="c-instagram-feed__title"><Instagram height={40} width={40} className="c-instagram-feed__title-img" /> Instagram Feed</h1>
        <h3 className="c-instagram-feed__sub-title">Bij kapsalon Kelly is uw haar in goeie handen. We doen ons best om u zo goed mogelijk te begeleiden in het vinden van uw ideale look. Hieronder vind je enkele van onze voorbeelden</h3>
        <div className="c-instagram-feed__feed">
            <div className="c-instagram-feed__feed-item">
                <StaticImage src="../../../images/instagram-1.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item">
                <StaticImage src="../../../images/instagram-2.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item">
                <StaticImage src="../../../images/instagram-3.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item">
                <StaticImage src="../../../images/instagram-4.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item">
                <StaticImage src="../../../images/instagram-5.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item">
                <StaticImage src="../../../images/instagram-6.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item hide-tablet">
                <StaticImage src="../../../images/instagram-7.jpg" />
            </div>
            <div className="c-instagram-feed__feed-item hide-tablet">
                <StaticImage src="../../../images/instagram-8.jpg" />
            </div>
        </div>
        <KapsalonBtn text="Bekijk ons Instagram" />
    </div>)
}

export default InstagramFeed;