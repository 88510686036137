import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import './KapsalonJumbotron.scss'
import Scissors from '../../../images/scissors.svg';

const KapsalonJumbotron = () => {
    return (
        <div className="c-kapsalon-jumbotron">
            <div className="c-kapsalon-jumbotron__image-wrapper">
                <StaticImage src="../../../images/jumbotronkelly.jpg" quality={100} objectFit="cover" objectPosition="center bottom" className="c-kapsalon-jumbotron__image" />
            </div>
            <h1 className="c-kapsalon-jumbotron__title">Uw specialist in knippen in Heusden</h1>
            <h3 className="c-kapsalon-jumbotron__sub-title">VOOR KINDEREN EN VROUWEN</h3>
            <Scissors height={30} className="c-kapsalon-jumbotron__separator" />
            <div className="c-kapsalon-jumbotron__text">
                DI-ZAT | 10u00 - 19u00
            </div>
            <div className="c-kapsalon-jumbotron__text">
                Enkel op afspraak
            </div>
            <a href="tel:0476772530" className="c-kapsalon-jumbotron__phone">
                0476 77 25 30
            </a>
        </div>
    )
}

export default KapsalonJumbotron