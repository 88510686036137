import React from 'react'
import './KapsalonBtn.scss'

const KapsalonBtn = ({text}) => {

    return (
        <div className="c-kapsalon-btn">
            {text}
        </div>
    )
}

export default KapsalonBtn