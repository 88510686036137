import React from 'react'
import Slider from 'react-slick'
import './ClientReviews.scss'
import Favorite from '../../../images/favorite.svg';
import Link from '../../../images/link.svg';
import KapsalonTitle from '../shared/KapsalonTitle';

const ClientReviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000
    };

    return (
        <div className="c-client-reviews">
            <div className="c-kapsalon-container">
                <KapsalonTitle Svg={Favorite} title="Onze klantenbeoordelingen" />
                <Slider className="c-client-reviews-slider" {...settings} >
                    <div>
                        <div className="c-client-reviews__review">
                            <div className="c-client-reviews__review-text">
                                Kelly helpt mij altijd bij het vinden van de ideale nieuwe coupe! Echte aanrader! 
                            </div>
                            <div className="c-client-reviews__review-foot-note">
                                <div className="c-client-reviews__review-foot-note-name">
                                    Lina Dewaele
                                </div>
                                <div className="c-client-reviews__review-foot-note-source">
                                    <Link className="c-client-reviews-icon c-client-reviews-icon--small" /> Geplaatst op onze Facebook pagina
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="c-client-reviews__review">
                            <div className="c-client-reviews__review-text">
                                Als je op zoek bent naar een professionele behandeling dan ben je bij Kelly aan het juiste addres. Je kan
                                bij haar ook altijd terecht voor een goede babbel, een echte aanrader!
                            </div>
                            <div className="c-client-reviews__review-foot-note">
                                <div className="c-client-reviews__review-foot-note-name">
                                    Erica Maes
                                </div>
                                <div className="c-client-reviews__review-foot-note-source">
                                <Link className="c-client-reviews-icon c-client-reviews-icon--small" /> Geplaatst op Google
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default ClientReviews;