import React from 'react'
import './KapsalonCtas.scss'
import FileText from '../../../images/filetext.svg'
import Coffee from '../../../images/coffee.svg'
import MessageCircle from '../../../images/message-circle.svg'

const KapsalonCta = ({ Icon, title, text, btn }) => {

    return (
        <div className="c-kapsalon-cta">
            <div className="c-kapsalon-cta__icon">
                <Icon height={40} width={50}/>
            </div>
            <div className="c-kapsalon-cta__title">
            {title}
            </div>
            <p className="c-kapsalon-cta__text">
                {text}
            </p>
            <p className="c-kapsalon-cta__button">
                {btn}
            </p>
        </div>
    )
}

const KapsalonCtas = () => {

    return (
        <div className="c-kapsalon-ctas c-kapsalon-container">
            {/* <FileText /> */}
            <KapsalonCta Icon={FileText} title="Document met onze tarieven" text="Klik op de knop om onze prijslijst te bekijken (snit, kleuring, Beauty, Nanokeratin, tie & dye... )" btn="Download onze tarieven"></KapsalonCta>
            <KapsalonCta Icon={Coffee} title="Het Salon, een warme verwelkomende ruimte" text="Kelly, kapster, styliste & visagiste is er om naar u te luisteren en u te leiden in uw keuzes" btn="Salon ontdekken"></KapsalonCta>
            <KapsalonCta Icon={MessageCircle} title="Contact en informatie formulier" text="Informatie nodig over onze zaak of onze service? We zullen u zo rap mogelijk bijstaan." btn="Ons contacteren"></KapsalonCta>
        </div>
    )
}

export default KapsalonCtas