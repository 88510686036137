import React from 'react'
import './KapsalonTitle.scss'

const KapsalonTitle = ( { Svg, title }) => {
    return <h1 className="c-kapsalon-title">
        <Svg height={40} width={40} className="c-kapsalon-title__icon"></Svg> {title}
    </h1>
}

export default KapsalonTitle

