import React from 'react'
import KapsalonTitle from '../shared/KapsalonTitle'
import Coffee from '../../../images/coffee.svg'

import './OnsKapsalon.scss'
import { StaticImage } from 'gatsby-plugin-image'

const OnsKapsalon = ({ reference }) => {

    return (
        <div className="c-ons-kapsalon c-kapsalon-container" id="salon" ref={reference}>
           <KapsalonTitle Svg={Coffee} title="Ons salon"/>  
           <p className="c-ons-kapsalon__intro">
                In ons salon kan je steeds terecht voor de laatste trends.
                Onze kapster en visagiste staan u bij om uw perfecte look te vinden.
           </p>
           <div className="c-ons-kapsalon__images">
                <StaticImage src="../../../images/kelly_1.jpg" />
                <StaticImage src="../../../images/kelly_2.jpg" />
           </div>
        </div>
    )
}

export default OnsKapsalon;