import React from 'react'

import './KapsalonSeparator.scss'

const KapsalonSeparator = () => {

    return (
        <div className="c-kapsalon-separator"></div>
    )
}

export default KapsalonSeparator