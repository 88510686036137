import React, { useRef } from 'react'
import { Helmet } from "react-helmet"
import KapsalonHeader from '../components/kapsalon/header/KapsalonHeader'
import KapsalonBanner from '../components/kapsalon/banner/KapsalonBanner'
import KapsalonCtas from '../components/kapsalon/cta/KapsalonCtas'
import KapsalonSeparator from '../components/kapsalon/shared/KapsalonSeparator'
import InstagramFeed from '../components/kapsalon/instagramfeed/InstagramFeed'
import ClientReviews from '../components/kapsalon/clientreviews/ClientReviews'
import OnsContacteren from '../components/kapsalon/onscontacteren/OnsContacteren'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OnsKapsalon from '../components/kapsalon/kapsalon/OnsKapsalon'
import KapsalonFooter from '../components/kapsalon/footer/KapsalonFooter'
import KapsalonJumbotron from '../components/kapsalon/jumbotron/KapsalonJumbotron'

export default function Kapsalon({location, data}){

    const salonRef = React.createRef(); 
    const contactRef = React.createRef();

    const scrollToSalon = () => salonRef.current.scrollIntoView() 
    const scrollToContact = () => contactRef.current.scrollIntoView() 

    return(
        <>
        <Helmet htmlAttributes={{
        lang: 'nl',
        }}>
            <meta charSet="utf-8" />
            <title>Kapsalon kelly - kapsalon in Heusden</title>
            <meta name="description" content="Kapsalon kelly, uw kapsalon in heusden" />
        </Helmet>
        <KapsalonHeader scrollToSalon={scrollToSalon} scrollToContact={scrollToContact} />
        <KapsalonJumbotron />
        <KapsalonCtas />
        <KapsalonSeparator />
        <InstagramFeed />
        <ClientReviews />
        <OnsKapsalon reference={salonRef} />
        <KapsalonSeparator />
        <OnsContacteren reference={contactRef} />
        <KapsalonFooter />
        </>
    )
}