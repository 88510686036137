import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import ArrowUp from '../../../images/arrow_up.svg'

import './KapsalonFooter.scss'

const KapsalonFooter = () => {

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }
    
    return (
        <footer className="c-kapsalon-footer">
            <ul className="c-kapsalon-footer__list c-kapsalon-container">
                <li className="c-kapsalon-footer__list-item">
                    <StaticImage src={'../../../images/kelly_dark.jpg'} width={250} />
                    <div className="c-kapsalon-footer__list-item-text">© 2021 Kapsalon Kelly. Alle rechten voorbehouden.</div>
                    <div className="c-kapsalon-footer__top-btn" onClick={handleClick}><ArrowUp /></div>
                </li>
            </ul>
        </footer>
    )
}

export default KapsalonFooter;